<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <BrandLogo :class="windowWidth < 992 ? 'd-none' : '' "/>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <SideBrand :imgUrl="imgUrl"/>        
      <!-- /Left Text-->
      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">                                    
          <Login :user="usuario" @login-user="login" v-if="!isLogged"/>
          <!-- loader al pedir datos a la api -->
          <div class="text-center mt-2 mb-2" v-if="isLoadingAuth">
            <b-spinner label="Loading..." variant="info" />
          </div>         
          <SelectPOS v-if="isLogged && user && !puntoventa"/>          
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import BrandLogo from '@/modules/auth/components/BrandLogo'
import SideBrand from '@/modules/auth/components/SideBrand'
import Login from '@/modules/auth/components/Login'
import SelectPOS from '@/modules/auth/components/SelectPOS'


import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import store from '@/store/index'


export default { 
  components: {    
    BrandLogo,
    SideBrand,
    Login,
    SelectPOS
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {      
      sideImg: require('@/assets/images/login/login-v2-dark.png'),            
      usuario: {
        username:'',
        password:''        
      }
    }
  },
  computed: {
    ...mapState('auth',['isLogged', 'user', 'isLoadingAuth','quote']),
    ...mapState('shop',['canalventa','puntoventa']),
    ...mapState('appConfig',['windowWidth']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapMutations('auth', ['logout']),
    ...mapMutations('shop',['unsetCanalVenta','unsetPuntoDeVenta','setEmptyCart','unsetLastNameSearch','unsetDataRoom']),
    ...mapMutations('bookings',['resetToInitialState']),
    ...mapMutations('products',['unsetProducts']),
    async login(){           
      await this.signIn( this.usuario ) 
      if( this.isLogged && this.canalventa && this.puntoventa){
        let timeout = 10000
        this.$router.push({name: 'sales'})
        
      }     
    },
    clearDataApp(){
      this.logout()
      this.unsetCanalVenta()
      this.unsetPuntoDeVenta()
      this.setEmptyCart()
      this.unsetLastNameSearch()
      this.unsetDataRoom()
      this.resetToInitialState()
      this.unsetProducts()
      localStorage.removeItem('vuex')     
      window.location.reload()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
